import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TabSharedService {
    private selectedIndex$ = new BehaviorSubject<number>(0);
    private teamNumberValue: number;
    private teamLettersValue: string[];
    private teamContacts = {
        PrimaryCoach: 0,
        FinancialContact: 0,
        SecondaryCoach: 0,
        OrganizationAdministrativeDistrictContact: 0,
    };
    private organizationTypeId: number;
    private organizationName: string;
    private teamNumberGenerated: boolean;

    setSelectedIndex(index: number) {
        this.selectedIndex$.next(index);
    }

    getSelectedIndex() {
        return this.selectedIndex$.asObservable();
    }

    setTeamNumber(teamNumber: number) {
        this.teamNumberValue = teamNumber;
    }

    getTeamNumber() {
        return this.teamNumberValue;
    }

    setTeamLetters(teamLetters: string[]) {
        this.teamLettersValue = teamLetters;
    }

    getTeamLetters() {
        return this.teamLettersValue;
    }

    setTeamContacts(index: string, id: number) {
        this.teamContacts[index] = id;
    }

    getTeamContacts() {
        return this.teamContacts;
    }

    setOrganizationTypeId(id: number) {
        return this.organizationTypeId = id;
    }

    getOrganizationTypeId() {
        return this.organizationTypeId;
    }

    setOrganizationName(name: string) {
        return this.organizationName = name;
    }

    getOrganizationName() {
        return this.organizationName;
    }

    setTeamNumberGenerated(isGenerated: boolean) {
        return this.teamNumberGenerated = isGenerated;
    }

    getTeamNumberGenerated() {
        return this.teamNumberGenerated;
    }
    
}
