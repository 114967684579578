// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=testing` then `environment.testing.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    apiVersion: '1',
    appName: 'REC Foundation Events',
    appVersion: '1.0.0',
    assetsPath: 'assets/',
    authClientId: 1,
    baseApiUrl: '',
    docPath: 'docs/',
    googleApiKey: '', // Empty this string to disable google login
    imgPath: 'docs/images/',
    logger: false,
    msAuthConfig: null,
    production: false,
    siteName: 'REC Foundation Events',
    submitHelpUrl: 'http://submit-help.testing.milesapp.com',
    googleMaps: {
        apiKey: 'AIzaSyBX4S6jyAazWeu7TNbrOWU6GGAC7SvHS0g',
        regionMapId: 'c6fb628f6813fd6a'
    },
    teamLettersDefault: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'M', 'N', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
    ]
};
